<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="stroke-black"
  >
    <path d="M5 12H19" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>
